.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 80, 115, 0.7);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
  box-shadow: 1px 0 10px -3px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 80, 115, 0.7);
  position: absolute;
  pointer-events: none;
}

#tree path,
.branch path {
  fill: #dcdee0;
}
/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: "\25C0";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
  content: "\25B2";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.scaling-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
  /* override this inline for aspect ratio other than square */
}
.scaling-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

circle.outer {
  opacity: 0;
  fill: none;
  stroke-dasharray: 2, 2;
  stroke: grey;
}

.texty {
  fill: #b0b0b0;
}

#BubbleChart {
  margin-top: 15px;
}
