/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 10, 2017 */



@font-face {
    font-family: 'san_francisco_textbold';
    src: url('../fonts/sanfrancisco/sanfranciscotext-bold-webfont.woff2') format('woff2'),
         url('../fonts/sanfrancisco/sanfranciscotext-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textbold_italic';
    src: url('../fonts/sanfrancisco/sanfranciscotext-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/sanfrancisco/sanfranciscotext-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textlight';
    src: url('../fonts/sanfrancisco/sanfranciscotext-light-webfont.woff2') format('woff2'),
         url('../fonts/sanfrancisco/sanfranciscotext-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textLtIt';
    src: url('../fonts/sanfrancisco/sanfranciscotext-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/sanfrancisco/sanfranciscotext-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textregular';
    src: url('../fonts/sanfrancisco/sanfranciscotext-regular-webfont.woff2') format('woff2'),
         url('../fonts/sanfrancisco/sanfranciscotext-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textitalic';
    src: url('../fonts/sanfrancisco/sanfranciscotext-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/sanfrancisco/sanfranciscotext-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}