@import url("bootstrap/bootstrap.css");
@import url("open-iconic-bootstrap.css");
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');*/
@import url("type-sanfrancisco.css");

body {
  margin: 0;
  padding: 0;
  font-family: san_francisco_textregular, sans-serif;
  font-size: 100%;
  color: rgb(111, 109, 128);
  background-color: #f6f6f6;
  transition: 300ms all linear;
  overflow-x: hidden;
}

html,
body {
  min-height: 100%;
  height: 100%;
}
#root {
  overflow: hidden;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 6px;
}

#nav-icon3 span:nth-child(4) {
  top: 12px;
}

.open #nav-icon3 span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.open #nav-icon3 span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.open #nav-icon3 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.open #nav-icon3 span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}
#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/*Sortable List*/
.list-item-dragger {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve"> <path fill="#fff" color="#fff" d="M0,3.216v5h50v-5H0z M0,15.696v5h50v-5H0z M0,28.178v5h50v-5H0z M0,40.775v5h50v-5H0z"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.45;
  margin-right: 10px;
  cursor: row-resize;
}
.draggingItem {
  opacity: 0.75;
  z-index: 999;
}
.item {
  position: relative;
}

.stylizedList {
  position: relative;
  z-index: 0;
  background-color: transparent;
  outline: none;
  margin-left: 1rem;
  height: 100%;
  max-height: 300px;
  overflow: auto;
}
.stylizedItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px 10px 0;
  background-color: transparent;

  box-sizing: border-box;
  user-select: none;

  color: #333;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.1;
}

/*Temp form controls filter editor css*/
.filter_icon {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><path fill="#ccc" d="M0,64l192,192v192l128-32V256L512,64H0z"/></g></svg>');
}
.filterHeader {
  height: 35px;
  line-height: 35px;
  margin-top: 40px;
  margin-bottom: 15px;
}

.rule input {
  display: inline-block;
}

.condition .button,
.rule .button {
  vertical-align: none;
  margin-left: 10px;
  margin-bottom: 0;
}

.condition select,
.rule select {
  width: auto;
}

/*Rotate text to read vertically*/
.rotate-left-90 {
  position: absolute;
  top: 50%;
  left: -55px;
  width: 150px;
  right: 0px;
  transform: rotate(-90deg);
  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

/*buttons*/
button {
  border: 0;
  background: none;
  cursor: pointer;
  outline: none !important;
}
body,
* {
  -webkit-margin-before: 0;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
}
ul {
  padding-left: 0;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-family: san_francisco_textbold;
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}
h1,
h2,
h3,
h4 {
  font-family: san_francisco_textregular, serif;
  margin-top: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  color: #27313d;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #cacaca;
}

h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 0.9;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 1.1875rem;
  line-height: 0.8;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

#infographic h3 {
  line-height: 1;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.9375rem;
  }
  h4 {
    font-size: 1.5625rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #2ba6cb;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus {
  color: #258faf;
  text-decoration: none;
}
a img {
  border: 0;
}

.float-right {
  float: right !important;
}
.filter-new span {
  font-size: 60%;
  vertical-align: 20%;
}

/*Data table*/
.react-grid-Container {
  width: auto !important;
  margin-bottom: 15px;
  padding: 0 !important;
  background: #fff;
  box-shadow: 0 12px 18px -15px;
}
.react-grid-HeaderCell__draggable {
  z-index: 9999;
}
.react-grid-HeaderCell {
  white-space: normal !important;
  padding: 9px !important;
  background: #323140 !important;
  border-right: 0px !important;
  display: table !important;
}
.react-grid-Canvas,
.react-grid-HeaderRow {
  width: 100% !important;
}
.react-grid-Grid,
.react-grid-Main {
  outline: 0 !important;
  border: 0 !important;
}
.widget-HeaderCell__value {
  font-size: 0.9rem;
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  text-align: center;
  font-size: 13px;
  line-height: 1.1;
}
.react-grid-Cell__value {
  font-size: 0.9rem;
}
.react-grid-Row.row-context-menu .react-grid-Cell,
.react-grid-Row:hover .react-grid-Cell {
  background-color: #f5fdff !important;
}
.margin-right {
  margin-right: 10px;
}
.btn:focus,
.btn.focus {
  -webkit-box-shadow: initial;
  box-shadow: initial;
}
.btn-default:focus,
.btn-default.focus,
.btn-reverse:hover,
.btn-default {
  cursor: pointer;
  color: #2ba6cb;
  background-color: #fff;
  border-color: #ccc;
  outline: none;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus,
.btn-default:active,
.btn-primary.editable-submit:hover,
.btn-default:hover {
  color: #fff;
  background-color: #2ba6cb;
  border-color: #2ba6cb;
  outline: none;
}
.btn-reverse:focus,
.btn-reverse {
  color: #fff;
  background-color: #2ba6cb;
  border-color: #2ba6cb;
  outline: none;
}
.btn-med-shadow:focus,
.btn-med-shadow {
  box-shadow: 0 8px 14px -6px #999;
}
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
.btn-circle {
  border-radius: 100px;
}
.btn-circle--blue {
  background-color: #2ba6cb;
  color: #fff;
  padding: 4px 9px 5px;
  margin-top: 10px;
}
.btn-circle--blue:hover {
  background-color: #fff;
  color: #2ba6cb;
  border: 1px solid #ccc;
}
.btn-circle--big {
  border: 1px solid #ccc;
  padding: 0 10px 3px;
}
.btn-circle--big:hover {
  border: 1px solid #2ba6cb;
  background-color: #2ba6cb;
  color: #fff;
}
.btn-default.editable-cancel,
.btn-primary.editable-submit {
  color: #ccc;
  background-color: transparent;
  border-color: #ccc;
  margin-bottom: 15px;
  border-radius: 0;
  width: 30px;
  height: 30px;
  line-height: 1.7;
}
.glyphicon {
  margin-right: 5px;
}
.btn-primary .glyphicon,
.btn-default .glyphicon {
  margin-right: 0;
}
.btn-default.editable-cancel:hover {
  background: red;
  color: #fff;
  border-color: red;
}
h5 {
  margin-bottom: 1rem;
  color: #888;
}
pre {
  background-color: #fff;
  padding: 20px;
  font-size: 0.9rem;
}

/*Boostrap Additions*/
.headerWrapper {
  width: 100%;
  clear: both;
  background-color: #fff;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}

.footerWrapper {
  background-color: #f5f5f5;
  width: 100%;
  font-size: 0.8rem;
  border-top: 1px solid #ddd;
}
.footerWrapper img {
  max-width: 15px;
}
.tempLogoName {
  margin: 20px 0px;
  display: block;
  height: 40px;
  line-height: 40px;
  /*vertical-align: bottom;*/
  float: right;
  font-size: 1.8rem;
  font-weight: lighter;
}

.binkra-logo {
  max-width: 70px;
}
.pata-logo {
  max-width: 130px;
}
.logo__container {
  background-color: #323140;
  padding: 20px 0;
}
.logo__container img {
  max-width: 30px;
}
@media only screen and (min-width: 480px) {
  .binkra-logo {
    max-width: 100%;
  }
  .pata-logo {
    max-width: 100%;
  }
}

/*Sub Page Nav for Demo*/

.btn {
  padding: 0.7rem 0.75rem 0.5rem;
  font-size: 0.85rem;
}

/*MAP*/
path:focus {
  outline: none;
}
.dashContainer__transparent {
  background-color: transparent;
  padding: 20px;
}
.dashContainer__dark button {
  color: #fff;
  padding: 2px 5px;
  margin-right: 5px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  min-width: 22px;
}

/*Funnel */

h2,
h6 {
  text-align: left;
  margin-bottom: 1.5rem;
}

/* Inforgraphic page */
.flashyHeader {
  color: #fff;
  padding: 20px;
  background: #6b34ad; /* Old browsers */
  background: -webkit-linear-gradient(bottom left, #6b34ad 0%, #450099 100%);
  background: -moz-linear-gradient(bottom left, #6b34ad 0%, #450099 100%);
  background: -o-linear-gradient(bottom left, #6b34ad 0%, #450099 100%);
  background: linear-gradient(
    to top right,
    #6b34ad 0%,
    #450099 100%
  ); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b34ad', endColorstr='#450099',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
@media only screen and (min-width: 40em) {
  .flashyHeader h2 {
    font-size: 3.54rem;
    line-height: 1;
  }
  .flashyHeader span {
    font-size: 1.7rem;
  }
}
.flashyHeader span {
  padding: 10px 10px 0px;
  text-align: right;
  display: block;
}
.sources-p {
  font-size: 12px;
  padding: 15px 0px;
}
.info-box {
  color: #fff;
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0 12px 18px -15px rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
}

@media screen and (min-width: 768px) {
  .info-box {
    margin-bottom: 0;
  }
}

.info-box ul {
  margin-left: 18px;
}
.info-box ul li {
  line-height: 1.2;
  padding-bottom: 15px;
  font-size: 0.9rem;
}

.info-box.small {
  padding: 15px;
}
.AMERbox {
  background-color: #323140;
}
.AMERbox h5,
.AMERbox h6 {
  background-color: #323140;
  border-bottom: 1px solid #6f6d80;
}
.EMEAbox {
  background-color: #2ba6cb;
}
.EMEAbox h5,
.EMEAbox h6 {
  border-bottom: 1px solid #67bcd6;
}
.APJCbox {
  background-color: #18b569;
}
.APJCbox h5,
.APJCbox h6 {
  background-color: #18b569;
  border-bottom: 1px solid #5dcc97;
}
.SQLbyProgram {
  background-color: #888;
}
.SQLbyProgram h5 {
  background-color: #888;
  border-bottom: 1px solid #999;
}
.paddingTop {
  padding-top: 30px;
}
.info-heading {
  margin-bottom: 40px;
}
.funnel-side {
  padding: 35px 0 0;
  margin: 0;
}
.info-box h5,
.info-box h6 {
  margin-bottom: 30px;
  color: #fff;
  padding: 0 0 20px;
}

.info-box.small {
  max-width: 110px;
}
.small.AMERbox {
  position: absolute;
  bottom: 30%;
  left: 15%;
}
.small.EMEAbox {
  position: absolute;
  bottom: 35%;
  left: 45%;
}
.small.APJCbox {
  position: absolute;
  bottom: 30%;
  left: 70%;
}
.totalSqlbox {
  padding: 20px;
  font-weight: bold;
  background-color: #7b95a7;
  font-size: 1.4rem;
}

/* Loading Indicator */
.loaderWrap {
  height: 100px;
  line-height: 100px;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  position: relative !important;
  z-index: 999;
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  width: 40px;
  margin: 0px 5px 0 0;
  position: relative;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*infogrpahic barchart*/
.recharts-wrapper {
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}
.mt-r4 {
  margin-top: 4rem;
}
.mt-6 {
  margin-top: 6rem;
}
.mb-6 {
  margin-bottom: 6rem;
}
.mt-7 {
  margin-top: 7.5rem;
}

/*tooltip*/
.wsdm-tooltip {
  display: none;
  padding: 0.5rem 1rem;
  background: rgba(50, 49, 64, 0.8);
  border-radius: 3px;
  pointer-events: none;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  color: #fff;
}
.wsdm-tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -0.5rem;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid rgba(50, 49, 64, 0.8);
}
.tinyPill {
  color: rgb(43, 166, 203) !important;
  font-weight: normal;
  font-size: 13px;
  padding: 5px 15px 4px;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0 5px 10px -6px #999;
}
.tinyPill:hover {
  background: rgb(43, 166, 203);
  color: #fff !important;
}
.headerPanel {
  margin-bottom: 30px;
}
.whiteSectionPanel {
  padding: 25px 25px 30px;
  background-color: #fff;
  margin-bottom: 50px;
  -webkit-box-shadow: 0 15px 32px -25px rgba(50, 49, 64, 0.8);
  -moz-box-shadow: 0 15px 32px -25px rgba(50, 49, 64, 0.8);
  box-shadow: 0 15px 32px -25px rgba(50, 49, 64, 0.8);
}

/* Print Infographic PDF */
body.print .row {
  display: inherit !important;
}
body.print .col-xs-12,
body.print .col-sm-12,
body.print .col-md-12,
body.print .col-lg-12 {
  width: 100%;
  max-width: 100%;

  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-3,
body.print .col-lg-3 {
  margin-right: 20px;
  margin-left: 20px;
  max-width: 33%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-4,
body.print .col-lg-4 {
  max-width: 33.333333%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-5,
body.print .col-lg-5 {
  max-width: 41.666667%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-6,
body.print .col-lg-6 {
  max-width: 50%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}

body.print .col-md-7,
body.print .col-lg-7 {
  max-width: 58.333333%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-8 {
  max-width: 66.666667%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .whiteSectionPanel {
  padding: 25px 0px 30px;
  overflow: hidden;
}
body.print .pageBreak {
  page-break-before: always;
}
body.print .channelProgress {
  margin-top: 50px !important;
}
body.print .headingMargin {
  margin-top: 40px;
}

body.print .channelRegional {
  margin-top: 140px;
}
body.print .footerWrapper {
  border: 0;
}

/*GraphModule*/
.rc-slider-tooltip {
  z-index: 999;
}
.Popover-tipShape {
  fill: #ffffff;
}
.header {
  padding: 5px;
  width: 140px;
  display: inline-block;
  font-weight: bold;
  text-align: left;
}
.cell {
  padding: 5px;
  width: 140px;
  display: inline-block;
}
.dot {
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 10px;
}
