body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: rgba(0, 80, 115, 0.7);
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
  box-shadow: 1px 0 10px -3px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 80, 115, 0.7);
  position: absolute;
  pointer-events: none;
}

#tree path,
.branch path {
  fill: #dcdee0;
}
/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: "\25C0";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
  content: "\25B2";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.scaling-svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
  /* override this inline for aspect ratio other than square */
}
.scaling-svg {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

circle.outer {
  opacity: 0;
  fill: none;
  stroke-dasharray: 2, 2;
  stroke: grey;
}

.texty {
  fill: #b0b0b0;
}

#BubbleChart {
  margin-top: 15px;
}

aws-sld__box,.aws-sld__container figure,.aws-sld__content{
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0
}
.aws-sld__bullets button,.aws-sld__controls button{
    outline-color:0;
    outline-style:none;
    outline-width:0
}
.aws-sld{
    --organic-arrow-thickness:3px;
    --organic-arrow-height:15px;
    --slider-height-percentage:30%;
    --loader-bar-color:#851515;
    --loader-bar-height:6px;
    --control-button-width:10%;
    --control-button-height:25%;
    --control-button-opacity:0.5;
    --control-button-hover-opacity:0.75;
    --control-button-background:transparent;
    --transition-bezier:cubic-bezier(0.5,0.075,0.25,0.95);
    --slider-transition-duration:575ms;
    --organic-arrow-color:#6a6a6a;
    --organic-arrow-border-radius:0;
    --control-bullet-color:#6a6a6a;
    --control-bullet-active-color:#6a6a6a;
    --content-background-color:#fff;
    width:100%;
    max-width:100%
}
.aws-sld,.aws-sld__wrapper{
    display:block;
    position:relative;
    max-height: 150px;
}
.aws-sld__wrapper{
    overflow:hidden
}
.aws-sld__container{
    display:block;
    width:100%;
    height:0;
    padding-bottom:var(--slider-height-percentage)
}

.aws-sld__startUp{
    background-color:red;
    height:100%;
    width:100%
}
.aws-sld__startUp>div{
    height:100%;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center
}
.aws-sld__startUp img{
    width:35%;
    height:auto
}
.aws-sld__content{
    background-color:var(--content-background-color);
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center
}
.aws-sld__content>img,.aws-sld__content>video{
    object-fit:cover;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0
}
.aws-sld__controls button{
    display:flex;
    align-items:center;
    justify-content:center;
    width:var(--control-button-width);
    height:var(--control-button-height);
    position:absolute;
    z-index:10;
    top:calc(50% - (.5 * var(--control-button-height)));
    border:none;
    background-color:var(--control-button-background);
    color:#fff;
    cursor:pointer
}
.aws-sld__controls button .aws-sld__controls__arrow-left,.aws-sld__controls button .aws-sld__controls__arrow-right{
    opacity:var(--control-button-opacity)
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-left,.aws-sld__controls button:hover .aws-sld__controls__arrow-right{
    opacity:var(--control-button-opacity-hover)
}
.aws-sld__controls--active .aws-sld__controls__arrow-left{
    opacity:var(--control-button-opacity-hover);
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0)
}
.aws-sld__controls--active .aws-sld__controls__arrow-right{
    opacity:var(--control-button-opacity-hover);
    -webkit-transform:translate3d(100%,0,0);
    transform:translate3d(100%,0,0)
}
@media (max-width:520px){
    .aws-sld__controls{
        visibility:visible !important
    }
}
.aws-sld__bar{
    display:block;
    width:100%;
    height:var(--loader-bar-height);
    background-color:var(--loader-bar-color);
    position:absolute;
    top:0;
    left:0;
    -webkit-transition:-webkit-transform 3s var(--transition-bezier);
    transition:-webkit-transform 3s var(--transition-bezier);
    transition:transform 3s var(--transition-bezier);
    transition:transform 3s var(--transition-bezier), -webkit-transform 3s var(--transition-bezier);
    transition:transform 3s var(--transition-bezier),-webkit-transform 3s var(--transition-bezier);
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0)
}
.aws-sld__bar--active{
    -webkit-transform:translate3d(-20%,0,0);
    transform:translate3d(-20%,0,0)
}
.aws-sld__bar--end{
    -webkit-transition-duration:.3s;
    transition-duration:.3s;
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
}
.aws-sld__next{
    right:0
}
.aws-sld__prev{
    left:0
}
.aws-sld__box{
    z-index:1
}
.aws-sld--animated{
    -webkit-transition:-webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition:-webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition:transform var(--slider-transition-duration) var(--transition-bezier);
    transition:transform var(--slider-transition-duration) var(--transition-bezier), -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition:transform var(--slider-transition-duration) var(--transition-bezier),-webkit-transform var(--slider-transition-duration) var(--transition-bezier)
}
.aws-sld--animated-mobile{
    -webkit-transition:-webkit-transform 325ms cubic-bezier(.15,.65,.1,1);
    transition:-webkit-transform 325ms cubic-bezier(.15,.65,.1,1);
    transition:transform 325ms cubic-bezier(.15,.65,.1,1);
    transition:transform 325ms cubic-bezier(.15,.65,.1,1), -webkit-transform 325ms cubic-bezier(.15,.65,.1,1);
    transition:transform 325ms cubic-bezier(.15,.65,.1,1),-webkit-transform 325ms cubic-bezier(.15,.65,.1,1)
}
.aws-sld--active{
    z-index:2;
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
}
.aws-sld--moveRight{
    -webkit-transform:translate3d(100%,0,0);
    transform:translate3d(100%,0,0)
}
.aws-sld--moveLeft{
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0)
}
.aws-sld--exit{
    z-index:0
}
.aws-sld--first .aws-sld__prev,.aws-sld--last .aws-sld__next{
    visibility:hidden
}
.aws-sld__bullets{
    position:absolute;
    bottom:-40px;
    width:100%;
    display:-ms-flexbox;
    display:none;
    align-items:center;
    justify-content:center
}
.aws-sld__bullets button{
    padding:0;
    display:block;
    width:16px;
    height:16px;
    margin:5px;
    border-radius:50%;
    background:var(--control-bullet-color);
    text-indent:-9999px;
    overflow:hidden;
    cursor:pointer;
    border:none;
    -webkit-transition:background-color .175s ease-out,-webkit-transform .225s cubic-bezier(.8,1.35,.75,1.45);
    transition:background-color .175s ease-out,-webkit-transform .225s cubic-bezier(.8,1.35,.75,1.45);
    transition:transform .225s cubic-bezier(.8,1.35,.75,1.45),background-color .175s ease-out;
    transition:transform .225s cubic-bezier(.8,1.35,.75,1.45),background-color .175s ease-out,-webkit-transform .225s cubic-bezier(.8,1.35,.75,1.45)
}
.aws-sld__bullets .aws-sld__bullets--loading,.aws-sld__bullets button:hover{
    -webkit-transform:scale(1.2);
    transform:scale(1.2)
}
.aws-sld__bullets .aws-sld__bullets--active{
    background:var(--control-bullet-active-color)
}
.aws-sld__bullets .aws-sld__bullets--active,.aws-sld__bullets .aws-sld__bullets--active:hover{
    -webkit-transform:scale(1.5);
    transform:scale(1.5)
}
.aws-sld__controls__arrow-left,.aws-sld__controls__arrow-right{
    width:100%;
    height:var(--organic-arrow-height);
    position:relative;
    display:block;
    -webkit-transition:opacity .2s ease-out,-webkit-transform .2s ease-out .125s;
    transition:opacity .2s ease-out,-webkit-transform .2s ease-out .125s;
    transition:transform .2s ease-out .125s,opacity .2s ease-out;
    transition:transform .2s ease-out .125s,opacity .2s ease-out,-webkit-transform .2s ease-out .125s
}
.aws-sld__controls__arrow-left:after,.aws-sld__controls__arrow-left:before,.aws-sld__controls__arrow-right:after,.aws-sld__controls__arrow-right:before{
    content:" ";
    position:absolute;
    right:calc(50% - (.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2);
    height:100%;
    border-radius:var(--organic-arrow-border-radius);
    width:var(--organic-arrow-thickness);
    background-color:var(--organic-arrow-color);
    -webkit-transition:background-color .15s ease-out,-webkit-transform .15s ease-out;
    transition:background-color .15s ease-out,-webkit-transform .15s ease-out;
    transition:transform .15s ease-out,background-color .15s ease-out;
    transition:transform .15s ease-out,background-color .15s ease-out,-webkit-transform .15s ease-out
}
.aws-sld__controls__arrow-left:before,.aws-sld__controls__arrow-right:before{
    -webkit-transform-origin:100% 100% 0;
    transform-origin:100% 100% 0;
    top:-50%;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg)
}
.aws-sld__controls__arrow-left:after,.aws-sld__controls__arrow-right:after{
    -webkit-transform-origin:100% 0 0;
    transform-origin:100% 0 0;
    top:50%;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.aws-sld__controls__arrow-right--active{
    -webkit-transform:translate3d(100%,0,0);
    transform:translate3d(100%,0,0)
}
.aws-sld__controls__arrow-right--active:after{
    -webkit-transform:rotate(90deg) translate3d(50%,0,0)!important;
    transform:rotate(90deg) translate3d(50%,0,0)!important
}
.aws-sld__controls__arrow-right--active:before{
    -webkit-transform:rotate(-90deg) translate3d(50%,0,0)!important;
    transform:rotate(-90deg) translate3d(50%,0,0)!important
}
.aws-sld__controls__arrow-left:after,.aws-sld__controls__arrow-left:before{
    right:auto;
    left:calc(50% - (.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2)
}
.aws-sld__controls__arrow-left:before{
    -webkit-transform-origin:0 100% 0;
    transform-origin:0 100% 0;
    top:-50%;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.aws-sld__controls__arrow-left:after{
    -webkit-transform-origin:0 0 0;
    transform-origin:0 0 0;
    top:50%;
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg)
}
.aws-sld__controls__arrow-left--active{
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0)
}
.aws-sld__controls__arrow-left--active:after{
    -webkit-transform:rotate(-90deg) translate3d(-50%,0,0)!important;
    transform:rotate(-90deg) translate3d(-50%,0,0)!important
}
.aws-sld__controls__arrow-left--active:before{
    -webkit-transform:rotate(90deg) translate3d(-50%,0,0)!important;
    transform:rotate(90deg) translate3d(-50%,0,0)!important
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-left:before{
    opacity:1;
    -webkit-transform:rotate(30deg);
    transform:rotate(30deg)
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-left:after,.aws-sld__controls button:hover .aws-sld__controls__arrow-right:before{
    opacity:1;
    -webkit-transform:rotate(-30deg);
    transform:rotate(-30deg)
}
.aws-sld__controls button:hover .aws-sld__controls__arrow-right:after{
    opacity:1;
    -webkit-transform:rotate(30deg);
    transform:rotate(30deg)
}

@charset "UTF-8";

@font-face {
  font-family: "dripicons-v2";
  src:url(/static/media/dripicons-v2.7e12564e.eot);
  src:url(/static/media/dripicons-v2.7e12564e.eot?#iefix) format("embedded-opentype"),
    url(/static/media/dripicons-v2.11fc83ae.woff) format("woff"),
    url(/static/media/dripicons-v2.cf09c981.ttf) format("truetype"),
    url(/static/media/dripicons-v2.83598443.svg#dripicons-v2) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dripicons-"]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dripicons-alarm:before {
  content: "a";
}
.dripicons-align-center:before {
  content: "b";
}
.dripicons-align-justify:before {
  content: "c";
}
.dripicons-align-left:before {
  content: "d";
}
.dripicons-align-right:before {
  content: "e";
}
.dripicons-anchor:before {
  content: "f";
}
.dripicons-archive:before {
  content: "g";
}
.dripicons-arrow-down:before {
  content: "h";
}
.dripicons-arrow-left:before {
  content: "i";
}
.dripicons-arrow-right:before {
  content: "j";
}
.dripicons-arrow-thin-down:before {
  content: "k";
}
.dripicons-arrow-thin-left:before {
  content: "l";
}
.dripicons-arrow-thin-right:before {
  content: "m";
}
.dripicons-arrow-thin-up:before {
  content: "n";
}
.dripicons-arrow-up:before {
  content: "o";
}
.dripicons-article:before {
  content: "p";
}
.dripicons-backspace:before {
  content: "q";
}
.dripicons-basket:before {
  content: "r";
}
.dripicons-basketball:before {
  content: "s";
}
.dripicons-battery-empty:before {
  content: "t";
}
.dripicons-battery-full:before {
  content: "u";
}
.dripicons-battery-low:before {
  content: "v";
}
.dripicons-battery-medium:before {
  content: "w";
}
.dripicons-bell:before {
  content: "x";
}
.dripicons-blog:before {
  content: "y";
}
.dripicons-bluetooth:before {
  content: "z";
}
.dripicons-bold:before {
  content: "A";
}
.dripicons-bookmark:before {
  content: "B";
}
.dripicons-bookmarks:before {
  content: "C";
}
.dripicons-box:before {
  content: "D";
}
.dripicons-briefcase:before {
  content: "E";
}
.dripicons-brightness-low:before {
  content: "F";
}
.dripicons-brightness-max:before {
  content: "G";
}
.dripicons-brightness-medium:before {
  content: "H";
}
.dripicons-broadcast:before {
  content: "I";
}
.dripicons-browser:before {
  content: "J";
}
.dripicons-browser-upload:before {
  content: "K";
}
.dripicons-brush:before {
  content: "L";
}
.dripicons-calendar:before {
  content: "M";
}
.dripicons-camcorder:before {
  content: "N";
}
.dripicons-camera:before {
  content: "O";
}
.dripicons-card:before {
  content: "P";
}
.dripicons-cart:before {
  content: "Q";
}
.dripicons-checklist:before {
  content: "R";
}
.dripicons-checkmark:before {
  content: "S";
}
.dripicons-chevron-down:before {
  content: "T";
}
.dripicons-chevron-left:before {
  content: "U";
}
.dripicons-chevron-right:before {
  content: "V";
}
.dripicons-chevron-up:before {
  content: "W";
}
.dripicons-clipboard:before {
  content: "X";
}
.dripicons-clock:before {
  content: "Y";
}
.dripicons-clockwise:before {
  content: "Z";
}
.dripicons-cloud:before {
  content: "0";
}
.dripicons-cloud-download:before {
  content: "1";
}
.dripicons-cloud-upload:before {
  content: "2";
}
.dripicons-code:before {
  content: "3";
}
.dripicons-contract:before {
  content: "4";
}
.dripicons-contract-2:before {
  content: "5";
}
.dripicons-conversation:before {
  content: "6";
}
.dripicons-copy:before {
  content: "7";
}
.dripicons-crop:before {
  content: "8";
}
.dripicons-cross:before {
  content: "9";
}
.dripicons-crosshair:before {
  content: "!";
}
.dripicons-cutlery:before {
  content: "\"";
}
.dripicons-device-desktop:before {
  content: "#";
}
.dripicons-device-mobile:before {
  content: "$";
}
.dripicons-device-tablet:before {
  content: "%";
}
.dripicons-direction:before {
  content: "&";
}
.dripicons-disc:before {
  content: "'";
}
.dripicons-document:before {
  content: "(";
}
.dripicons-document-delete:before {
  content: ")";
}
.dripicons-document-edit:before {
  content: "*";
}
.dripicons-document-new:before {
  content: "+";
}
.dripicons-document-remove:before {
  content: ",";
}
.dripicons-dot:before {
  content: "-";
}
.dripicons-dots-2:before {
  content: ".";
}
.dripicons-dots-3:before {
  content: "/";
}
.dripicons-download:before {
  content: ":";
}
.dripicons-duplicate:before {
  content: ";";
}
.dripicons-enter:before {
  content: "<";
}
.dripicons-exit:before {
  content: "=";
}
.dripicons-expand:before {
  content: ">";
}
.dripicons-expand-2:before {
  content: "?";
}
.dripicons-experiment:before {
  content: "@";
}
.dripicons-export:before {
  content: "[";
}
.dripicons-feed:before {
  content: "]";
}
.dripicons-flag:before {
  content: "^";
}
.dripicons-flashlight:before {
  content: "_";
}
.dripicons-folder:before {
  content: "`";
}
.dripicons-folder-open:before {
  content: "{";
}
.dripicons-forward:before {
  content: "|";
}
.dripicons-gaming:before {
  content: "}";
}
.dripicons-gear:before {
  content: "~";
}
.dripicons-graduation:before {
  content: "\\";
}
.dripicons-graph-bar:before {
  content: "\E000";
}
.dripicons-graph-line:before {
  content: "\E001";
}
.dripicons-graph-pie:before {
  content: "\E002";
}
.dripicons-headset:before {
  content: "\E003";
}
.dripicons-heart:before {
  content: "\E004";
}
.dripicons-help:before {
  content: "\E005";
}
.dripicons-home:before {
  content: "\E006";
}
.dripicons-hourglass:before {
  content: "\E007";
}
.dripicons-inbox:before {
  content: "\E008";
}
.dripicons-information:before {
  content: "\E009";
}
.dripicons-italic:before {
  content: "\E00A";
}
.dripicons-jewel:before {
  content: "\E00B";
}
.dripicons-lifting:before {
  content: "\E00C";
}
.dripicons-lightbulb:before {
  content: "\E00D";
}
.dripicons-link:before {
  content: "\E00E";
}
.dripicons-link-broken:before {
  content: "\E00F";
}
.dripicons-list:before {
  content: "\E010";
}
.dripicons-loading:before {
  content: "\E011";
}
.dripicons-location:before {
  content: "\E012";
}
.dripicons-lock:before {
  content: "\E013";
}
.dripicons-lock-open:before {
  content: "\E014";
}
.dripicons-mail:before {
  content: "\E015";
}
.dripicons-map:before {
  content: "\E016";
}
.dripicons-media-loop:before {
  content: "\E017";
}
.dripicons-media-next:before {
  content: "\E018";
}
.dripicons-media-pause:before {
  content: "\E019";
}
.dripicons-media-play:before {
  content: "\E01A";
}
.dripicons-media-previous:before {
  content: "\E01B";
}
.dripicons-media-record:before {
  content: "\E01C";
}
.dripicons-media-shuffle:before {
  content: "\E01D";
}
.dripicons-media-stop:before {
  content: "\E01E";
}
.dripicons-medical:before {
  content: "\E01F";
}
.dripicons-menu:before {
  content: "\E020";
}
.dripicons-message:before {
  content: "\E021";
}
.dripicons-meter:before {
  content: "\E022";
}
.dripicons-microphone:before {
  content: "\E023";
}
.dripicons-minus:before {
  content: "\E024";
}
.dripicons-monitor:before {
  content: "\E025";
}
.dripicons-move:before {
  content: "\E026";
}
.dripicons-music:before {
  content: "\E027";
}
.dripicons-network-1:before {
  content: "\E028";
}
.dripicons-network-2:before {
  content: "\E029";
}
.dripicons-network-3:before {
  content: "\E02A";
}
.dripicons-network-4:before {
  content: "\E02B";
}
.dripicons-network-5:before {
  content: "\E02C";
}
.dripicons-pamphlet:before {
  content: "\E02D";
}
.dripicons-paperclip:before {
  content: "\E02E";
}
.dripicons-pencil:before {
  content: "\E02F";
}
.dripicons-phone:before {
  content: "\E030";
}
.dripicons-photo:before {
  content: "\E031";
}
.dripicons-photo-group:before {
  content: "\E032";
}
.dripicons-pill:before {
  content: "\E033";
}
.dripicons-pin:before {
  content: "\E034";
}
.dripicons-plus:before {
  content: "\E035";
}
.dripicons-power:before {
  content: "\E036";
}
.dripicons-preview:before {
  content: "\E037";
}
.dripicons-print:before {
  content: "\E038";
}
.dripicons-pulse:before {
  content: "\E039";
}
.dripicons-question:before {
  content: "\E03A";
}
.dripicons-reply:before {
  content: "\E03B";
}
.dripicons-reply-all:before {
  content: "\E03C";
}
.dripicons-return:before {
  content: "\E03D";
}
.dripicons-retweet:before {
  content: "\E03E";
}
.dripicons-rocket:before {
  content: "\E03F";
}
.dripicons-scale:before {
  content: "\E040";
}
.dripicons-search:before {
  content: "\E041";
}
.dripicons-shopping-bag:before {
  content: "\E042";
}
.dripicons-skip:before {
  content: "\E043";
}
.dripicons-stack:before {
  content: "\E044";
}
.dripicons-star:before {
  content: "\E045";
}
.dripicons-stopwatch:before {
  content: "\E046";
}
.dripicons-store:before {
  content: "\E047";
}
.dripicons-suitcase:before {
  content: "\E048";
}
.dripicons-swap:before {
  content: "\E049";
}
.dripicons-tag:before {
  content: "\E04A";
}
.dripicons-tag-delete:before {
  content: "\E04B";
}
.dripicons-tags:before {
  content: "\E04C";
}
.dripicons-thumbs-down:before {
  content: "\E04D";
}
.dripicons-thumbs-up:before {
  content: "\E04E";
}
.dripicons-ticket:before {
  content: "\E04F";
}
.dripicons-time-reverse:before {
  content: "\E050";
}
.dripicons-to-do:before {
  content: "\E051";
}
.dripicons-toggles:before {
  content: "\E052";
}
.dripicons-trash:before {
  content: "\E053";
}
.dripicons-trophy:before {
  content: "\E054";
}
.dripicons-upload:before {
  content: "\E055";
}
.dripicons-user:before {
  content: "\E056";
}
.dripicons-user-group:before {
  content: "\E057";
}
.dripicons-user-id:before {
  content: "\E058";
}
.dripicons-vibrate:before {
  content: "\E059";
}
.dripicons-view-apps:before {
  content: "\E05A";
}
.dripicons-view-list:before {
  content: "\E05B";
}
.dripicons-view-list-large:before {
  content: "\E05C";
}
.dripicons-view-thumb:before {
  content: "\E05D";
}
.dripicons-volume-full:before {
  content: "\E05E";
}
.dripicons-volume-low:before {
  content: "\E05F";
}
.dripicons-volume-medium:before {
  content: "\E060";
}
.dripicons-volume-off:before {
  content: "\E061";
}
.dripicons-wallet:before {
  content: "\E062";
}
.dripicons-warning:before {
  content: "\E063";
}
.dripicons-web:before {
  content: "\E064";
}
.dripicons-weight:before {
  content: "\E065";
}
.dripicons-wifi:before {
  content: "\E066";
}
.dripicons-wrong:before {
  content: "\E067";
}
.dripicons-zoom-in:before {
  content: "\E068";
}
.dripicons-zoom-out:before {
  content: "\E069";
}

/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96;
}

.blockquote-footer::before {
  content: "\2014   \A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
}

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}

a > code {
  padding: 0;
  color: inherit;
  background-color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .container {
    max-width: 560px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 740px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
  border-top: 2px solid #e9ecef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e9ecef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e9ecef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.thead-inverse th {
  color: #fff;
  background-color: #212529;
}

.thead-default th {
  color: #495057;
  background-color: #e9ecef;
}

.table-inverse {
  color: #fff;
  background-color: #212529;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #32383e;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-inverse.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-inverse.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive.table-bordered {
    border: 0;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
}

.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.75rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  display: inline-block;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .invalid-tooltip,
.form-control.is-valid ~ .invalid-feedback,
.form-control.is-valid ~ .invalid-tooltip,
.was-validated .custom-select:valid ~ .invalid-feedback,
.was-validated .custom-select:valid ~ .invalid-tooltip,
.custom-select.is-valid ~ .invalid-feedback,
.custom-select.is-valid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label,
.form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator,
.custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid ~ .custom-control-description,
.custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control,
.custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before,
.custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid:focus,
.custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label,
.form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator,
.custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid ~ .custom-control-description,
.custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control,
.custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before,
.custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid:focus,
.custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:active,
.btn.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0069d9;
  background-image: none;
  border-color: #0062cc;
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #727b84;
  background-image: none;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #218838;
  background-image: none;
  border-color: #1e7e34;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  background-color: #138496;
  background-image: none;
  border-color: #117a8b;
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background-color: #e0a800;
  background-image: none;
  border-color: #d39e00;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: #c82333;
  background-image: none;
  border-color: #bd2130;
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-color: #e2e6ea;
  background-image: none;
  border-color: #dae0e5;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background-color: #23272b;
  background-image: none;
  border-color: #1d2124;
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link:disabled {
  background-color: transparent;
}

.btn-link,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
  box-shadow: none;
}

.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link:disabled {
  color: #868e96;
}

.btn-link:disabled:focus,
.btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.show > a {
  outline: 0;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
  margin-bottom: 0;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical
  > .btn-group:first-child:not(:last-child)
  > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical
  > .btn-group:last-child:not(:first-child)
  > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  width: 100%;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group .form-control:focus,
.input-group .form-control:active,
.input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child)
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:focus,
.input-group-btn > .btn:active,
.input-group-btn > .btn:hover {
  z-index: 3;
}

.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.input-group-btn:not(:first-child) > .btn:focus,
.input-group-btn:not(:first-child) > .btn:active,
.input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff;
}

.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}

.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}

.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}

.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd;
}

.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.show > .nav-pills .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-brand {
  color: white;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: white;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group .card {
    flex: 1 0;
  }
  .card-group .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .card-group .card:not(:first-child):not(:last-child) .card-img-top,
  .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb::after {
  display: block;
  clear: both;
  content: "";
}

.breadcrumb-item {
  float: left;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #868e96;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd;
}

.page-link:focus,
.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #ddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}

.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #6c757d;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #111;
  background-color: #ffc107;
}

.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #111;
  background-color: #f8f9fa;
}

.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #111;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}

.alert-secondary hr {
  border-top-color: #cfd2d6;
}

.alert-secondary .alert-link {
  color: #2e3133;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:focus,
.list-group-item:hover {
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #868e96;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085;
}

a.list-group-item-primary:focus,
a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
  color: #004085;
  background-color: #9fcdff;
}

a.list-group-item-primary.active,
button.list-group-item-primary.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2;
}

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e;
}

a.list-group-item-secondary:focus,
a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #464a4e;
  background-color: #cfd2d6;
}

a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #155724;
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #155724;
  background-color: #b1dfbb;
}

a.list-group-item-success.active,
button.list-group-item-success.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460;
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #0c5460;
  background-color: #abdde5;
}

a.list-group-item-info.active,
button.list-group-item-info.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404;
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #856404;
  background-color: #ffe8a1;
}

a.list-group-item-warning.active,
button.list-group-item-warning.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24;
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

a.list-group-item-danger.active,
button.list-group-item-danger.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

a.list-group-item-light,
button.list-group-item-light {
  color: #818182;
}

a.list-group-item-light:focus,
a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
  color: #818182;
  background-color: #ececf6;
}

a.list-group-item-light.active,
button.list-group-item-light.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21;
}

a.list-group-item-dark:focus,
a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

a.list-group-item-dark.active,
button.list-group-item-dark.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
}

.tooltip.bs-tooltip-top,
.tooltip.bs-tooltip-auto[x-placement^="top"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-top .arrow,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.bs-tooltip-right,
.tooltip.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-right .arrow,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
}

.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  margin-top: -3px;
  content: "";
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.bs-tooltip-bottom,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 5px 0;
}

.tooltip.bs-tooltip-bottom .arrow,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  margin-left: -3px;
  content: "";
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bs-tooltip-left,
.tooltip.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 5px;
}

.tooltip.bs-tooltip-left .arrow,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
}

.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  margin-top: -3px;
  content: "";
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip .arrow::before {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow::before {
  content: "";
  border-width: 11px;
}

.popover .arrow::after {
  content: "";
  border-width: 11px;
}

.popover.bs-popover-top,
.popover.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 10px;
}

.popover.bs-popover-top .arrow,
.popover.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-bottom-width: 0;
}

.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: -11px;
  margin-left: -6px;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: -10px;
  margin-left: -6px;
  border-top-color: #fff;
}

.popover.bs-popover-right,
.popover.bs-popover-auto[x-placement^="right"] {
  margin-left: 10px;
}

.popover.bs-popover-right .arrow,
.popover.bs-popover-auto[x-placement^="right"] .arrow {
  left: 0;
}

.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  margin-top: -8px;
  border-left-width: 0;
}

.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: -11px;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: -10px;
  border-right-color: #fff;
}

.popover.bs-popover-bottom,
.popover.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 10px;
}

.popover.bs-popover-bottom .arrow,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  margin-left: -7px;
  border-top-width: 0;
}

.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: -11px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: -10px;
  border-bottom-color: #fff;
}

.popover.bs-popover-bottom .popover-header::before,
.popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 20px;
  margin-left: -10px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.popover.bs-popover-left,
.popover.bs-popover-auto[x-placement^="left"] {
  margin-right: 10px;
}

.popover.bs-popover-left .arrow,
.popover.bs-popover-auto[x-placement^="left"] .arrow {
  right: 0;
}

.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  margin-top: -8px;
  border-right-width: 0;
}

.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: -11px;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: -10px;
  border-left-color: #fff;
}

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 9px 14px;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (
  ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports (
  ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports (
  ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:focus,
.carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

.d-print-block {
  display: none !important;
}

@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}

@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #6c757d !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}

.text-muted {
  color: #868e96 !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}
/*# sourceMappingURL=bootstrap.css.map */

/* Bootstrap */

@font-face {
  font-family: 'Icons';
  src: url(/static/media/open-iconic.47151e87.eot);
  src: url(/static/media/open-iconic.47151e87.eot?#iconic-sm) format('embedded-opentype'), url(/static/media/open-iconic.3cf97837.woff) format('woff'), url(/static/media/open-iconic.93cc7aa6.ttf) format('truetype'), url(/static/media/open-iconic.527eff64.otf) format('opentype'), url(/static/media/open-iconic.eca2c267.svg#iconic-sm) format('svg');
  font-weight: normal;
  font-style: normal;
}

.oi, .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  speak:none;
  font-family: 'Icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oi:empty:before, .glyphicon:empty:before {
  width: 1em;
  text-align: center;
  box-sizing: content-box;
}

.oi.oi-align-center:before {
  text-align: center;
}

.oi.oi-align-left:before {
  text-align: left;
}

.oi.oi-align-right:before {
  text-align: right;
}


.oi.oi-flip-horizontal:before {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.oi.oi-flip-vertical:before {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.oi.oi-flip-horizontal-vertical:before {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}


.oi-account-login:before {
  content:'\E000';
}

.oi-account-logout:before {
  content:'\E001';
}

.oi-action-redo:before {
  content:'\E002';
}

.oi-action-undo:before {
  content:'\E003';
}

.oi-align-center:before {
  content:'\E004';
}

.oi-align-left:before {
  content:'\E005';
}

.oi-align-right:before {
  content:'\E006';
}

.oi-aperture:before {
  content:'\E007';
}

.oi-arrow-bottom:before {
  content:'\E008';
}

.oi-arrow-circle-bottom:before {
  content:'\E009';
}

.oi-arrow-circle-left:before {
  content:'\E00A';
}

.oi-arrow-circle-right:before {
  content:'\E00B';
}

.oi-arrow-circle-top:before {
  content:'\E00C';
}

.oi-arrow-left:before {
  content:'\E00D';
}

.oi-arrow-right:before {
  content:'\E00E';
}

.oi-arrow-thick-bottom:before {
  content:'\E00F';
}

.oi-arrow-thick-left:before {
  content:'\E010';
}

.oi-arrow-thick-right:before {
  content:'\E011';
}

.oi-arrow-thick-top:before {
  content:'\E012';
}

.oi-arrow-top:before {
  content:'\E013';
}

.oi-audio-spectrum:before {
  content:'\E014';
}

.oi-audio:before {
  content:'\E015';
}

.oi-badge:before {
  content:'\E016';
}

.oi-ban:before {
  content:'\E017';
}

.oi-bar-chart:before {
  content:'\E018';
}

.oi-basket:before {
  content:'\E019';
}

.oi-battery-empty:before {
  content:'\E01A';
}

.oi-battery-full:before {
  content:'\E01B';
}

.oi-beaker:before {
  content:'\E01C';
}

.oi-bell:before {
  content:'\E01D';
}

.oi-bluetooth:before {
  content:'\E01E';
}

.oi-bold:before {
  content:'\E01F';
}

.oi-bolt:before {
  content:'\E020';
}

.oi-book:before {
  content:'\E021';
}

.oi-bookmark:before {
  content:'\E022';
}

.oi-box:before {
  content:'\E023';
}

.oi-briefcase:before {
  content:'\E024';
}

.oi-british-pound:before {
  content:'\E025';
}

.oi-browser:before {
  content:'\E026';
}

.oi-brush:before {
  content:'\E027';
}

.oi-bug:before {
  content:'\E028';
}

.oi-bullhorn:before {
  content:'\E029';
}

.oi-calculator:before {
  content:'\E02A';
}

.oi-calendar:before {
  content:'\E02B';
}

.oi-camera-slr:before {
  content:'\E02C';
}

.oi-caret-bottom:before {
  content:'\E02D';
}

.oi-caret-left:before {
  content:'\E02E';
}

.oi-caret-right:before {
  content:'\E02F';
}

.oi-caret-top:before {
  content:'\E030';
}

.oi-cart:before {
  content:'\E031';
}

.oi-chat:before {
  content:'\E032';
}

.oi-check:before, .glyphicon-ok:before {
  content:'\E033';
}

.oi-chevron-bottom:before {
  content:'\E034';
}

.oi-chevron-left:before {
  content:'\E035';
}

.oi-chevron-right:before {
  content:'\E036';
}

.oi-chevron-top:before {
  content:'\E037';
}

.oi-circle-check:before {
  content:'\E038';
}

.oi-circle-x:before {
  content:'\E039';
}

.oi-clipboard:before {
  content:'\E03A';
}

.oi-clock:before {
  content:'\E03B';
}

.oi-cloud-download:before {
  content:'\E03C';
}

.oi-cloud-upload:before {
  content:'\E03D';
}

.oi-cloud:before {
  content:'\E03E';
}

.oi-cloudy:before {
  content:'\E03F';
}

.oi-code:before {
  content:'\E040';
}

.oi-cog:before {
  content:'\E041';
}

.oi-collapse-down:before {
  content:'\E042';
}

.oi-collapse-left:before {
  content:'\E043';
}

.oi-collapse-right:before {
  content:'\E044';
}

.oi-collapse-up:before {
  content:'\E045';
}

.oi-command:before {
  content:'\E046';
}

.oi-comment-square:before {
  content:'\E047';
}

.oi-compass:before {
  content:'\E048';
}

.oi-contrast:before {
  content:'\E049';
}

.oi-copywriting:before {
  content:'\E04A';
}

.oi-credit-card:before {
  content:'\E04B';
}

.oi-crop:before {
  content:'\E04C';
}

.oi-dashboard:before {
  content:'\E04D';
}

.oi-data-transfer-download:before {
  content:'\E04E';
}

.oi-data-transfer-upload:before {
  content:'\E04F';
}

.oi-delete:before {
  content:'\E050';
}

.oi-dial:before {
  content:'\E051';
}

.oi-document:before {
  content:'\E052';
}

.oi-dollar:before {
  content:'\E053';
}

.oi-double-quote-sans-left:before {
  content:'\E054';
}

.oi-double-quote-sans-right:before {
  content:'\E055';
}

.oi-double-quote-serif-left:before {
  content:'\E056';
}

.oi-double-quote-serif-right:before {
  content:'\E057';
}

.oi-droplet:before {
  content:'\E058';
}

.oi-eject:before {
  content:'\E059';
}

.oi-elevator:before {
  content:'\E05A';
}

.oi-ellipses:before {
  content:'\E05B';
}

.oi-envelope-closed:before {
  content:'\E05C';
}

.oi-envelope-open:before {
  content:'\E05D';
}

.oi-euro:before {
  content:'\E05E';
}

.oi-excerpt:before {
  content:'\E05F';
}

.oi-expand-down:before {
  content:'\E060';
}

.oi-expand-left:before {
  content:'\E061';
}

.oi-expand-right:before {
  content:'\E062';
}

.oi-expand-up:before {
  content:'\E063';
}

.oi-external-link:before {
  content:'\E064';
}

.oi-eye:before {
  content:'\E065';
}

.oi-eyedropper:before {
  content:'\E066';
}

.oi-file:before {
  content:'\E067';
}

.oi-fire:before {
  content:'\E068';
}

.oi-flag:before {
  content:'\E069';
}

.oi-flash:before {
  content:'\E06A';
}

.oi-folder:before {
  content:'\E06B';
}

.oi-fork:before {
  content:'\E06C';
}

.oi-fullscreen-enter:before {
  content:'\E06D';
}

.oi-fullscreen-exit:before {
  content:'\E06E';
}

.oi-globe:before {
  content:'\E06F';
}

.oi-graph:before {
  content:'\E070';
}

.oi-grid-four-up:before {
  content:'\E071';
}

.oi-grid-three-up:before {
  content:'\E072';
}

.oi-grid-two-up:before {
  content:'\E073';
}

.oi-hard-drive:before {
  content:'\E074';
}

.oi-header:before {
  content:'\E075';
}

.oi-headphones:before {
  content:'\E076';
}

.oi-heart:before {
  content:'\E077';
}

.oi-home:before {
  content:'\E078';
}

.oi-image:before {
  content:'\E079';
}

.oi-inbox:before {
  content:'\E07A';
}

.oi-infinity:before {
  content:'\E07B';
}

.oi-info:before {
  content:'\E07C';
}

.oi-italic:before {
  content:'\E07D';
}

.oi-justify-center:before {
  content:'\E07E';
}

.oi-justify-left:before {
  content:'\E07F';
}

.oi-justify-right:before {
  content:'\E080';
}

.oi-key:before {
  content:'\E081';
}

.oi-laptop:before {
  content:'\E082';
}

.oi-layers:before {
  content:'\E083';
}

.oi-lightbulb:before {
  content:'\E084';
}

.oi-link-broken:before {
  content:'\E085';
}

.oi-link-intact:before {
  content:'\E086';
}

.oi-list-rich:before {
  content:'\E087';
}

.oi-list:before {
  content:'\E088';
}

.oi-location:before {
  content:'\E089';
}

.oi-lock-locked:before {
  content:'\E08A';
}

.oi-lock-unlocked:before {
  content:'\E08B';
}

.oi-loop-circular:before {
  content:'\E08C';
}

.oi-loop-square:before {
  content:'\E08D';
}

.oi-loop:before {
  content:'\E08E';
}

.oi-magnifying-glass:before {
  content:'\E08F';
}

.oi-map-marker:before {
  content:'\E090';
}

.oi-map:before {
  content:'\E091';
}

.oi-media-pause:before {
  content:'\E092';
}

.oi-media-play:before {
  content:'\E093';
}

.oi-media-record:before {
  content:'\E094';
}

.oi-media-skip-backward:before {
  content:'\E095';
}

.oi-media-skip-forward:before {
  content:'\E096';
}

.oi-media-step-backward:before {
  content:'\E097';
}

.oi-media-step-forward:before {
  content:'\E098';
}

.oi-media-stop:before {
  content:'\E099';
}

.oi-medical-cross:before {
  content:'\E09A';
}

.oi-menu:before {
  content:'\E09B';
}

.oi-microphone:before {
  content:'\E09C';
}

.oi-minus:before {
  content:'\E09D';
}

.oi-monitor:before {
  content:'\E09E';
}

.oi-moon:before {
  content:'\E09F';
}

.oi-move:before {
  content:'\E0A0';
}

.oi-musical-note:before {
  content:'\E0A1';
}

.oi-paperclip:before {
  content:'\E0A2';
}

.oi-pencil:before {
  content:'\E0A3';
}

.oi-people:before {
  content:'\E0A4';
}

.oi-person:before {
  content:'\E0A5';
}

.oi-phone:before {
  content:'\E0A6';
}

.oi-pie-chart:before {
  content:'\E0A7';
}

.oi-pin:before {
  content:'\E0A8';
}

.oi-play-circle:before {
  content:'\E0A9';
}

.oi-plus:before {
  content:'\E0AA';
}

.oi-power-standby:before {
  content:'\E0AB';
}

.oi-print:before {
  content:'\E0AC';
}

.oi-project:before {
  content:'\E0AD';
}

.oi-pulse:before {
  content:'\E0AE';
}

.oi-puzzle-piece:before {
  content:'\E0AF';
}

.oi-question-mark:before {
  content:'\E0B0';
}

.oi-rain:before {
  content:'\E0B1';
}

.oi-random:before {
  content:'\E0B2';
}

.oi-reload:before {
  content:'\E0B3';
}

.oi-resize-both:before {
  content:'\E0B4';
}

.oi-resize-height:before {
  content:'\E0B5';
}

.oi-resize-width:before {
  content:'\E0B6';
}

.oi-rss-alt:before {
  content:'\E0B7';
}

.oi-rss:before {
  content:'\E0B8';
}

.oi-script:before {
  content:'\E0B9';
}

.oi-share-boxed:before {
  content:'\E0BA';
}

.oi-share:before {
  content:'\E0BB';
}

.oi-shield:before {
  content:'\E0BC';
}

.oi-signal:before {
  content:'\E0BD';
}

.oi-signpost:before {
  content:'\E0BE';
}

.oi-sort-ascending:before {
  content:'\E0BF';
}

.oi-sort-descending:before {
  content:'\E0C0';
}

.oi-spreadsheet:before {
  content:'\E0C1';
}

.oi-star:before {
  content:'\E0C2';
}

.oi-sun:before {
  content:'\E0C3';
}

.oi-tablet:before {
  content:'\E0C4';
}

.oi-tag:before {
  content:'\E0C5';
}

.oi-tags:before {
  content:'\E0C6';
}

.oi-target:before {
  content:'\E0C7';
}

.oi-task:before {
  content:'\E0C8';
}

.oi-terminal:before {
  content:'\E0C9';
}

.oi-text:before {
  content:'\E0CA';
}

.oi-thumb-down:before {
  content:'\E0CB';
}

.oi-thumb-up:before {
  content:'\E0CC';
}

.oi-timer:before {
  content:'\E0CD';
}

.oi-transfer:before {
  content:'\E0CE';
}

.oi-trash:before {
  content:'\E0CF';
}

.oi-underline:before {
  content:'\E0D0';
}

.oi-vertical-align-bottom:before {
  content:'\E0D1';
}

.oi-vertical-align-center:before {
  content:'\E0D2';
}

.oi-vertical-align-top:before {
  content:'\E0D3';
}

.oi-video:before {
  content:'\E0D4';
}

.oi-volume-high:before {
  content:'\E0D5';
}

.oi-volume-low:before {
  content:'\E0D6';
}

.oi-volume-off:before {
  content:'\E0D7';
}

.oi-warning:before {
  content:'\E0D8';
}

.oi-wifi:before {
  content:'\E0D9';
}

.oi-wrench:before {
  content:'\E0DA';
}

.oi-x:before, .glyphicon-remove:before {
  content:'\E0DB';
}

.oi-yen:before {
  content:'\E0DC';
}

.oi-zoom-in:before {
  content:'\E0DD';
}

.oi-zoom-out:before {
  content:'\E0DE';
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 10, 2017 */



@font-face {
    font-family: 'san_francisco_textbold';
    src: url(/static/media/sanfranciscotext-bold-webfont.85434b45.woff2) format('woff2'),
         url(/static/media/sanfranciscotext-bold-webfont.8ee91236.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textbold_italic';
    src: url(/static/media/sanfranciscotext-bolditalic-webfont.965ff46a.woff2) format('woff2'),
         url(/static/media/sanfranciscotext-bolditalic-webfont.dc70f321.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textlight';
    src: url(/static/media/sanfranciscotext-light-webfont.471fd135.woff2) format('woff2'),
         url(/static/media/sanfranciscotext-light-webfont.870e64c3.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textLtIt';
    src: url(/static/media/sanfranciscotext-lightitalic-webfont.89bdd70c.woff2) format('woff2'),
         url(/static/media/sanfranciscotext-lightitalic-webfont.5a6c2995.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textregular';
    src: url(/static/media/sanfranciscotext-regular-webfont.71a1efdb.woff2) format('woff2'),
         url(/static/media/sanfranciscotext-regular-webfont.8e6348f0.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'san_francisco_textitalic';
    src: url(/static/media/sanfranciscotext-regularitalic-webfont.c6bcac79.woff2) format('woff2'),
         url(/static/media/sanfranciscotext-regularitalic-webfont.06bcbb5a.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');*/

body {
  margin: 0;
  padding: 0;
  font-family: san_francisco_textregular, sans-serif;
  font-size: 100%;
  color: rgb(111, 109, 128);
  background-color: #f6f6f6;
  -webkit-transition: 300ms all linear;
  transition: 300ms all linear;
  overflow-x: hidden;
}

html,
body {
  min-height: 100%;
  height: 100%;
}
#root {
  overflow: hidden;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 6px;
}

#nav-icon3 span:nth-child(4) {
  top: 12px;
}

.open #nav-icon3 span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.open #nav-icon3 span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.open #nav-icon3 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.open #nav-icon3 span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}
#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

/*Sortable List*/
.list-item-dragger {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve"> <path fill="#fff" color="#fff" d="M0,3.216v5h50v-5H0z M0,15.696v5h50v-5H0z M0,28.178v5h50v-5H0z M0,40.775v5h50v-5H0z"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.45;
  margin-right: 10px;
  cursor: row-resize;
}
.draggingItem {
  opacity: 0.75;
  z-index: 999;
}
.item {
  position: relative;
}

.stylizedList {
  position: relative;
  z-index: 0;
  background-color: transparent;
  outline: none;
  margin-left: 1rem;
  height: 100%;
  max-height: 300px;
  overflow: auto;
}
.stylizedItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px 10px 0;
  background-color: transparent;

  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  color: #333;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.1;
}

/*Temp form controls filter editor css*/
.filter_icon {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><path fill="#ccc" d="M0,64l192,192v192l128-32V256L512,64H0z"/></g></svg>');
}
.filterHeader {
  height: 35px;
  line-height: 35px;
  margin-top: 40px;
  margin-bottom: 15px;
}

.rule input {
  display: inline-block;
}

.condition .button,
.rule .button {
  vertical-align: none;
  margin-left: 10px;
  margin-bottom: 0;
}

.condition select,
.rule select {
  width: auto;
}

/*Rotate text to read vertically*/
.rotate-left-90 {
  position: absolute;
  top: 50%;
  left: -55px;
  width: 150px;
  right: 0px;
  transform: rotate(-90deg);
  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

/*buttons*/
button {
  border: 0;
  background: none;
  cursor: pointer;
  outline: none !important;
}
body,
* {
  -webkit-margin-before: 0;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
}
ul {
  padding-left: 0;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-family: san_francisco_textbold;
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}
h1,
h2,
h3,
h4 {
  font-family: san_francisco_textregular, serif;
  margin-top: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  color: #27313d;
  text-rendering: optimizeLegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #cacaca;
}

h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 0.9;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3 {
  font-size: 1.1875rem;
  line-height: 0.8;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

#infographic h3 {
  line-height: 1;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.9375rem;
  }
  h4 {
    font-size: 1.5625rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #2ba6cb;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:focus {
  color: #258faf;
  text-decoration: none;
}
a img {
  border: 0;
}

.float-right {
  float: right !important;
}
.filter-new span {
  font-size: 60%;
  vertical-align: 20%;
}

/*Data table*/
.react-grid-Container {
  width: auto !important;
  margin-bottom: 15px;
  padding: 0 !important;
  background: #fff;
  box-shadow: 0 12px 18px -15px;
}
.react-grid-HeaderCell__draggable {
  z-index: 9999;
}
.react-grid-HeaderCell {
  white-space: normal !important;
  padding: 9px !important;
  background: #323140 !important;
  border-right: 0px !important;
  display: table !important;
}
.react-grid-Canvas,
.react-grid-HeaderRow {
  width: 100% !important;
}
.react-grid-Grid,
.react-grid-Main {
  outline: 0 !important;
  border: 0 !important;
}
.widget-HeaderCell__value {
  font-size: 0.9rem;
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  text-align: center;
  font-size: 13px;
  line-height: 1.1;
}
.react-grid-Cell__value {
  font-size: 0.9rem;
}
.react-grid-Row.row-context-menu .react-grid-Cell,
.react-grid-Row:hover .react-grid-Cell {
  background-color: #f5fdff !important;
}
.margin-right {
  margin-right: 10px;
}
.btn:focus,
.btn.focus {
  box-shadow: none;
  box-shadow: initial;
}
.btn-default:focus,
.btn-default.focus,
.btn-reverse:hover,
.btn-default {
  cursor: pointer;
  color: #2ba6cb;
  background-color: #fff;
  border-color: #ccc;
  outline: none;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus,
.btn-default:active,
.btn-primary.editable-submit:hover,
.btn-default:hover {
  color: #fff;
  background-color: #2ba6cb;
  border-color: #2ba6cb;
  outline: none;
}
.btn-reverse:focus,
.btn-reverse {
  color: #fff;
  background-color: #2ba6cb;
  border-color: #2ba6cb;
  outline: none;
}
.btn-med-shadow:focus,
.btn-med-shadow {
  box-shadow: 0 8px 14px -6px #999;
}
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
.btn-circle {
  border-radius: 100px;
}
.btn-circle--blue {
  background-color: #2ba6cb;
  color: #fff;
  padding: 4px 9px 5px;
  margin-top: 10px;
}
.btn-circle--blue:hover {
  background-color: #fff;
  color: #2ba6cb;
  border: 1px solid #ccc;
}
.btn-circle--big {
  border: 1px solid #ccc;
  padding: 0 10px 3px;
}
.btn-circle--big:hover {
  border: 1px solid #2ba6cb;
  background-color: #2ba6cb;
  color: #fff;
}
.btn-default.editable-cancel,
.btn-primary.editable-submit {
  color: #ccc;
  background-color: transparent;
  border-color: #ccc;
  margin-bottom: 15px;
  border-radius: 0;
  width: 30px;
  height: 30px;
  line-height: 1.7;
}
.glyphicon {
  margin-right: 5px;
}
.btn-primary .glyphicon,
.btn-default .glyphicon {
  margin-right: 0;
}
.btn-default.editable-cancel:hover {
  background: red;
  color: #fff;
  border-color: red;
}
h5 {
  margin-bottom: 1rem;
  color: #888;
}
pre {
  background-color: #fff;
  padding: 20px;
  font-size: 0.9rem;
}

/*Boostrap Additions*/
.headerWrapper {
  width: 100%;
  clear: both;
  background-color: #fff;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}

.footerWrapper {
  background-color: #f5f5f5;
  width: 100%;
  font-size: 0.8rem;
  border-top: 1px solid #ddd;
}
.footerWrapper img {
  max-width: 15px;
}
.tempLogoName {
  margin: 20px 0px;
  display: block;
  height: 40px;
  line-height: 40px;
  /*vertical-align: bottom;*/
  float: right;
  font-size: 1.8rem;
  font-weight: lighter;
}

.binkra-logo {
  max-width: 70px;
}
.pata-logo {
  max-width: 130px;
}
.logo__container {
  background-color: #323140;
  padding: 20px 0;
}
.logo__container img {
  max-width: 30px;
}
@media only screen and (min-width: 480px) {
  .binkra-logo {
    max-width: 100%;
  }
  .pata-logo {
    max-width: 100%;
  }
}

/*Sub Page Nav for Demo*/

.btn {
  padding: 0.7rem 0.75rem 0.5rem;
  font-size: 0.85rem;
}

/*MAP*/
path:focus {
  outline: none;
}
.dashContainer__transparent {
  background-color: transparent;
  padding: 20px;
}
.dashContainer__dark button {
  color: #fff;
  padding: 2px 5px;
  margin-right: 5px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  min-width: 22px;
}

/*Funnel */

h2,
h6 {
  text-align: left;
  margin-bottom: 1.5rem;
}

/* Inforgraphic page */
.flashyHeader {
  color: #fff;
  padding: 20px;
  background: #6b34ad; /* Old browsers */
  background: -webkit-linear-gradient(bottom left, #6b34ad 0%, #450099 100%);
  background: -webkit-linear-gradient(
    bottom left,
    #6b34ad 0%,
    #450099 100%
  );
  background: linear-gradient(
    to top right,
    #6b34ad 0%,
    #450099 100%
  ); /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b34ad', endColorstr='#450099',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
@media only screen and (min-width: 40em) {
  .flashyHeader h2 {
    font-size: 3.54rem;
    line-height: 1;
  }
  .flashyHeader span {
    font-size: 1.7rem;
  }
}
.flashyHeader span {
  padding: 10px 10px 0px;
  text-align: right;
  display: block;
}
.sources-p {
  font-size: 12px;
  padding: 15px 0px;
}
.info-box {
  color: #fff;
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0 12px 18px -15px rgba(0, 0, 0, 0.8);
  font-size: 0.9rem;
}

@media screen and (min-width: 768px) {
  .info-box {
    margin-bottom: 0;
  }
}

.info-box ul {
  margin-left: 18px;
}
.info-box ul li {
  line-height: 1.2;
  padding-bottom: 15px;
  font-size: 0.9rem;
}

.info-box.small {
  padding: 15px;
}
.AMERbox {
  background-color: #323140;
}
.AMERbox h5,
.AMERbox h6 {
  background-color: #323140;
  border-bottom: 1px solid #6f6d80;
}
.EMEAbox {
  background-color: #2ba6cb;
}
.EMEAbox h5,
.EMEAbox h6 {
  border-bottom: 1px solid #67bcd6;
}
.APJCbox {
  background-color: #18b569;
}
.APJCbox h5,
.APJCbox h6 {
  background-color: #18b569;
  border-bottom: 1px solid #5dcc97;
}
.SQLbyProgram {
  background-color: #888;
}
.SQLbyProgram h5 {
  background-color: #888;
  border-bottom: 1px solid #999;
}
.paddingTop {
  padding-top: 30px;
}
.info-heading {
  margin-bottom: 40px;
}
.funnel-side {
  padding: 35px 0 0;
  margin: 0;
}
.info-box h5,
.info-box h6 {
  margin-bottom: 30px;
  color: #fff;
  padding: 0 0 20px;
}

.info-box.small {
  max-width: 110px;
}
.small.AMERbox {
  position: absolute;
  bottom: 30%;
  left: 15%;
}
.small.EMEAbox {
  position: absolute;
  bottom: 35%;
  left: 45%;
}
.small.APJCbox {
  position: absolute;
  bottom: 30%;
  left: 70%;
}
.totalSqlbox {
  padding: 20px;
  font-weight: bold;
  background-color: #7b95a7;
  font-size: 1.4rem;
}

/* Loading Indicator */
.loaderWrap {
  height: 100px;
  line-height: 100px;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  position: relative !important;
  z-index: 999;
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  width: 40px;
  margin: 0px 5px 0 0;
  position: relative;
  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

/*infogrpahic barchart*/
.recharts-wrapper {
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}
.mt-r4 {
  margin-top: 4rem;
}
.mt-6 {
  margin-top: 6rem;
}
.mb-6 {
  margin-bottom: 6rem;
}
.mt-7 {
  margin-top: 7.5rem;
}

/*tooltip*/
.wsdm-tooltip {
  display: none;
  padding: 0.5rem 1rem;
  background: rgba(50, 49, 64, 0.8);
  border-radius: 3px;
  pointer-events: none;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  color: #fff;
}
.wsdm-tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: 50%;
  margin-left: -0.5rem;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid rgba(50, 49, 64, 0.8);
}
.tinyPill {
  color: rgb(43, 166, 203) !important;
  font-weight: normal;
  font-size: 13px;
  padding: 5px 15px 4px;
  background: #fff;
  border-radius: 32px;
  box-shadow: 0 5px 10px -6px #999;
}
.tinyPill:hover {
  background: rgb(43, 166, 203);
  color: #fff !important;
}
.headerPanel {
  margin-bottom: 30px;
}
.whiteSectionPanel {
  padding: 25px 25px 30px;
  background-color: #fff;
  margin-bottom: 50px;
  box-shadow: 0 15px 32px -25px rgba(50, 49, 64, 0.8);
}

/* Print Infographic PDF */
body.print .row {
  display: inherit !important;
}
body.print .col-xs-12,
body.print .col-sm-12,
body.print .col-md-12,
body.print .col-lg-12 {
  width: 100%;
  max-width: 100%;

  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-3,
body.print .col-lg-3 {
  margin-right: 20px;
  margin-left: 20px;
  max-width: 33%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-4,
body.print .col-lg-4 {
  max-width: 33.333333%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-5,
body.print .col-lg-5 {
  max-width: 41.666667%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-6,
body.print .col-lg-6 {
  max-width: 50%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}

body.print .col-md-7,
body.print .col-lg-7 {
  max-width: 58.333333%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .col-md-8 {
  max-width: 66.666667%;
  float: none !important;
  display: inline-block;
  margin: 0 -0.125em;
  vertical-align: top;
}
body.print .whiteSectionPanel {
  padding: 25px 0px 30px;
  overflow: hidden;
}
body.print .pageBreak {
  page-break-before: always;
}
body.print .channelProgress {
  margin-top: 50px !important;
}
body.print .headingMargin {
  margin-top: 40px;
}

body.print .channelRegional {
  margin-top: 140px;
}
body.print .footerWrapper {
  border: 0;
}

/*GraphModule*/
.rc-slider-tooltip {
  z-index: 999;
}
.Popover-tipShape {
  fill: #ffffff;
}
.header {
  padding: 5px;
  width: 140px;
  display: inline-block;
  font-weight: bold;
  text-align: left;
}
.cell {
  padding: 5px;
  width: 140px;
  display: inline-block;
}
.dot {
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

/* General */
a {
  color: #00b7b9;
}
/* Overview Page*/

/* "Card"/ Component container */
.dashContainer__white {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
  border-bottom: 5px solid tan;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
}
body.print .dashContainer__white {
  border-bottom: 0px;
}

/* Buttons */
.btn-default,
.btn-turqoise {
  text-transform: uppercase;
  font-family: san_francisco_textregular, serif;

  padding-bottom: 0.6rem;
  font-size: 0.75rem;
  margin-bottom: 15px;
  border-radius: 0;
}
.btn-default,
.btn-default:focus,
.btn-default:active {
  color: #5e646d;
  border-color: #c2c2c2;
  background: #fff;
}

.btn-turqoise {
  color: #fff !important;
  font-weight: 600;
  background: #fa452b;
  font-weight: 600;
}
.btn-default:active:focus,
.btn-default:active,
.btn-default:hover {
  background: #c2c2c2;
  border-color: #c2c2c2;
  color: #fff;
}
.btn-default.btn-clear:hover {
  color: #fff !important;
}
.btn-turqoise:hover {
  background: #f35d59;
}
.btn-turqoise.gear {
  font-size: 1rem;
  background: #a5a5a5;
  padding: 0.415rem 0.6rem 0.555rem 0.8rem;
}

.btn-default:active:hover {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}
.btn-turqoise.gear:hover {
  background: #9b9b9b;
}
.btn-default.x-editor {
  margin-left: 10px;
}
.x-editor span img {
  max-width: 16px;
}

.expressionDropDownContainer {
  font-size: 0.9rem;
  font-weight: 400;
  font-family: san_francisco_textregular, sans-serif;
  display: flex;
  align-items: center;
}
.expressionDropdownLabel {
  margin-bottom: 15px;
  margin-right: 10px;
}
.expressionDropdown {
  border-color: #c2c2c2;
  width: 250px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
}
.expressionDropdown .Select-control {
  border-radius: 0;
  height: 38px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 38px !important;
}
.Select-value {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.btn-column-select {
  color: #fff;
  background: tan;
  border-radius: 0;
  padding: 4px 5px 1px;
  font-size: 0.7rem;
}
/* Loader */
.overviewPrint.loaderWrap {
  line-height: inherit;
  max-width: 260px;
  position: absolute !important;
  left: 0px;
  top: 0;
  text-align: left;
}

/* TABLEs */
.ReactTable .-pagination .-btn {
  background-color: tan !important;
  border-color: tan !important;
}
.ReactTable .-pagination {
  font-size: 0.8rem;
  justify-content: left;
}

.ReactTable .-pagination .-center {
  flex: 0 1;
  -ms-flex: 0;
  flex-wrap: initial;
}
.ReactTable .-pagination .-pageInfo {
  margin: 0 10px;
}
.ReactTable .-pagination input {
  border: 0;
  background: #f4f4f4;
}
.ReactTable .-pagination .-pageJump input {
  width: 40px;
  padding: 3px 4px;
}
.ReactTable .-pagination .-btn {
  width: auto;
  height: auto;
  height: initial;
  padding: 1px 5px 2px;
  border-radius: 0;
}
.ReactTable .-pagination .-btn:not([disabled]):hover,
.ReactTable .-pagination .-btn:hover {
  color: #fff;
  background: #2d0164;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 0 1;
  -ms-flex: 0;
}

.ReactTable .rt-table {
  box-shadow: none !important;
}
.ReactTable .rt-table,
.ReactTable .rt-tbody,
.ReactTable .rt-thead,
.ReactTable .rt-tr-group,
.ReactTable .rt-th,
.ReactTable .rt-td {
  display: flex;
}

/*  2 column width fix?*/
.ReactTable .rt-thead,
.ReactTable .rt-tr-group {
  width: 100%;
}

body.print .ReactTable .rt-tr {
  display: inline-flex;
  white-space: nowrap;
  display: table;
  width: 100%;
}
body.print .ReactTable .rt-td,
body.print .ReactTable .rt-th {
  display: table-cell;
}

.ReactTable .rt-thead.-header {
  background-color: #384353 !important;
}

.rt-table::-webkit-scrollbar {
  background: #f5f5f5;
}
.rt-table::-webkit-scrollbar-thumb {
  background-color: #005073;
  border: solid #f5f5f5 2px;
}
.rt-table::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.ReactTable .-pagination .-btn {
  background-color: #00b7b9;
  border-color: #00b7b9;
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #afbacc;
}
.ReactTable .rt-thead.-filters input {
  border: 0;
}
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 0px 0 0 #06b7ba;
}
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -0px 0 0 #06b7ba;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #fff;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #dddee1;
  overflow: hidden;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .rt-td {
  background: #dddee1;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover:before {
  border-left: 5px solid #005073;
}
.overview .ReactTable .rt-thead .rt-th,
.overview .ReactTable .rt-thead .rt-td,
.overview .ReactTable .rt-tbody .rt-td {
  padding: 17px 9px;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 0px;
}
.ReactTable .rt-tbody {
  overflow: hidden;
}
.rt-tbody .rt-td {
  background: #f4f4f4;
}
.rt-tr {
  font-size: 13px;
}

/* Funnel Viz */
#funnel {
  margin-bottom: 29px;
}
#funnel svg {
  display: block;
  margin: auto;
}

/* Inline Filter Table Toolbar*/

.inlineFilterTableToolbar .btn:not(:last-child) {
  margin-right: 10px;
}

/*Modal*/
.modal-dialog {
  margin-top: 96px;
}
.modal-content {
  border-radius: 0 !important;
}
.modal-content button.close {
  display: none;
}
.modal-content button {
  margin-bottom: 0 !important;
}
.modal-title {
  font-family: "san_francisco_textbold";
  font-weight: "bold";
  color: #27313d;
}
.modal-content string {
  color: #27313d;
}
@media (min-width: 768px) {
  .modal-content .col-md-5 {
    flex: 0 0 46.8666669%;
    max-width: 46.8666669%;
  }
  .modal-content .col-md-1 {
    flex: 0 0 6%;
    max-width: 6%;
    padding: 0 !important;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  .modal-content .col-md-1,
  .modal-content .col-md-5 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
  }
}

/* Extra */
h6 {
  font-weight: 600;
}
/*Custom top margin */
.mt-c4 {
  margin-top: 4rem;
}

.dataSourcesFooter {
  margin: 12px 3px 13px 0px;
}
.overview {
  margin-top: 170px;
}
body.print .overview {
  margin: 0;
  top: 0;
}

