/* General */
a {
  color: #00b7b9;
}
/* Overview Page*/

/* "Card"/ Component container */
.dashContainer__white {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
  border-bottom: 5px solid tan;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
}
body.print .dashContainer__white {
  border-bottom: 0px;
}

/* Buttons */
.btn-default,
.btn-turqoise {
  text-transform: uppercase;
  font-family: san_francisco_textregular, serif;

  padding-bottom: 0.6rem;
  font-size: 0.75rem;
  margin-bottom: 15px;
  border-radius: 0;
}
.btn-default,
.btn-default:focus,
.btn-default:active {
  color: #5e646d;
  border-color: #c2c2c2;
  background: #fff;
}

.btn-turqoise {
  color: #fff !important;
  font-weight: 600;
  background: #fa452b;
  font-weight: 600;
}
.btn-default:active:focus,
.btn-default:active,
.btn-default:hover {
  background: #c2c2c2;
  border-color: #c2c2c2;
  color: #fff;
}
.btn-default.btn-clear:hover {
  color: #fff !important;
}
.btn-turqoise:hover {
  background: #f35d59;
}
.btn-turqoise.gear {
  font-size: 1rem;
  background: #a5a5a5;
  padding: 0.415rem 0.6rem 0.555rem 0.8rem;
}

.btn-default:active:hover {
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}
.btn-turqoise.gear:hover {
  background: #9b9b9b;
}
.btn-default.x-editor {
  margin-left: 10px;
}
.x-editor span img {
  max-width: 16px;
}

.expressionDropDownContainer {
  font-size: 0.9rem;
  font-weight: 400;
  font-family: san_francisco_textregular, sans-serif;
  display: flex;
  align-items: center;
}
.expressionDropdownLabel {
  margin-bottom: 15px;
  margin-right: 10px;
}
.expressionDropdown {
  border-color: #c2c2c2;
  width: 250px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
}
.expressionDropdown .Select-control {
  border-radius: 0;
  height: 38px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 38px !important;
}
.Select-value {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.btn-column-select {
  color: #fff;
  background: tan;
  border-radius: 0;
  padding: 4px 5px 1px;
  font-size: 0.7rem;
}
/* Loader */
.overviewPrint.loaderWrap {
  line-height: inherit;
  max-width: 260px;
  position: absolute !important;
  left: 0px;
  top: 0;
  text-align: left;
}

/* TABLEs */
.ReactTable .-pagination .-btn {
  background-color: tan !important;
  border-color: tan !important;
}
.ReactTable .-pagination {
  font-size: 0.8rem;
  justify-content: left;
}

.ReactTable .-pagination .-center {
  flex: 0;
  -ms-flex: 0;
  flex-wrap: initial;
}
.ReactTable .-pagination .-pageInfo {
  margin: 0 10px;
}
.ReactTable .-pagination input {
  border: 0;
  background: #f4f4f4;
}
.ReactTable .-pagination .-pageJump input {
  width: 40px;
  padding: 3px 4px;
}
.ReactTable .-pagination .-btn {
  width: auto;
  height: initial;
  padding: 1px 5px 2px;
  border-radius: 0;
}
.ReactTable .-pagination .-btn:not([disabled]):hover,
.ReactTable .-pagination .-btn:hover {
  color: #fff;
  background: #2d0164;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 0;
  -ms-flex: 0;
}

.ReactTable .rt-table {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ReactTable .rt-table,
.ReactTable .rt-tbody,
.ReactTable .rt-thead,
.ReactTable .rt-tr-group,
.ReactTable .rt-th,
.ReactTable .rt-td {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/*  2 column width fix?*/
.ReactTable .rt-thead,
.ReactTable .rt-tr-group {
  width: 100%;
}

body.print .ReactTable .rt-tr {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  display: table;
  width: 100%;
}
body.print .ReactTable .rt-td,
body.print .ReactTable .rt-th {
  display: table-cell;
}

.ReactTable .rt-thead.-header {
  background-color: #384353 !important;
}

.rt-table::-webkit-scrollbar {
  background: #f5f5f5;
}
.rt-table::-webkit-scrollbar-thumb {
  background-color: #005073;
  border: solid #f5f5f5 2px;
}
.rt-table::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.ReactTable .-pagination .-btn {
  background-color: #00b7b9;
  border-color: #00b7b9;
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background: #afbacc;
}
.ReactTable .rt-thead.-filters input {
  border: 0;
}
.ReactTable .rt-thead .rt-th.-sort-asc {
  -webkit-box-shadow: inset 0 0px 0 0 #06b7ba;
  box-shadow: inset 0 0px 0 0 #06b7ba;
}
.ReactTable .rt-thead .rt-th.-sort-desc {
  -webkit-box-shadow: inset 0 -0px 0 0 #06b7ba;
  box-shadow: inset 0 -0px 0 0 #06b7ba;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #fff;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #dddee1;
  overflow: hidden;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .rt-td {
  background: #dddee1;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover:before {
  border-left: 5px solid #005073;
}
.overview .ReactTable .rt-thead .rt-th,
.overview .ReactTable .rt-thead .rt-td,
.overview .ReactTable .rt-tbody .rt-td {
  padding: 17px 9px;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 0px;
}
.ReactTable .rt-tbody {
  overflow: hidden;
}
.rt-tbody .rt-td {
  background: #f4f4f4;
}
.rt-tr {
  font-size: 13px;
}

/* Funnel Viz */
#funnel {
  margin-bottom: 29px;
}
#funnel svg {
  display: block;
  margin: auto;
}

/* Inline Filter Table Toolbar*/

.inlineFilterTableToolbar .btn:not(:last-child) {
  margin-right: 10px;
}

/*Modal*/
.modal-dialog {
  margin-top: 96px;
}
.modal-content {
  border-radius: 0 !important;
}
.modal-content button.close {
  display: none;
}
.modal-content button {
  margin-bottom: 0 !important;
}
.modal-title {
  font-family: "san_francisco_textbold";
  font-weight: "bold";
  color: #27313d;
}
.modal-content string {
  color: #27313d;
}
@media (min-width: 768px) {
  .modal-content .col-md-5 {
    -ms-flex: 0 0 46.8666669%;
    flex: 0 0 46.8666669%;
    max-width: 46.8666669%;
  }
  .modal-content .col-md-1 {
    -ms-flex: 0 0 6%;
    flex: 0 0 6%;
    max-width: 6%;
    padding: 0 !important;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  .modal-content .col-md-1,
  .modal-content .col-md-5 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
  }
}

/* Extra */
h6 {
  font-weight: 600;
}
/*Custom top margin */
.mt-c4 {
  margin-top: 4rem;
}

.dataSourcesFooter {
  margin: 12px 3px 13px 0px;
}
.overview {
  margin-top: 170px;
}
body.print .overview {
  margin: 0;
  top: 0;
}
